.is-sticky {
  position: fixed;
  width: 100%;
  background-color: white;
}

.promo-bg {
  background-color: #f9dd3d;
  padding: 4px;
  border-radius: 7px;
}