.auth-slider .slider-container{
  height: 700px;
}

.auth-slider .slick-dots{
  bottom : 10px;
  z-index : 3
}


.auth-slider .slick-dots li {
  margin: 0px;;
}

.auth-slider .slick-dots li button:before {
  font-size: 8px;
  opacity: 1;
  color: #15091033;
}

.slider-shadow{
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: yellow;
  bottom: 6px;
  z-index : 2;
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFFCC 100%) 0% 0% no-repeat padding-box;;
}


.google-btn button{
  opacity: 0!important;
}