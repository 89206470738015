.location-title-image {
  width: 100%;
  height: 412px;
}


.location-card {
  width: 298px;
}

.location-card img {
  width: 246px;
  height: 160px;
}

.location-main-bg {
  background: transparent linear-gradient(180deg, #ffffff 0%, #F8F8F8 100%) 0% 0% no-repeat padding-box;;
}

.location-detail-main {
  height: 842px;
}

.location-detail-description {
  background: transparent radial-gradient(ellipse at center, #ffffff, #f8f8f800 ) 0% 0% no-repeat padding-box;
}



@media (min-width: 1024px) {
  .location-card {
    width: 280px;
  }
  .location-title-image {
    width: 443px;
  }
}


@media (max-width: 800px) {
  .location-card {
    width: 100%;
  }
  .location-card img{
    width: 100%;
    height: 200px;
  }
}