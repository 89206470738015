@font-face {
    font-family: 'sans-pro-black';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-Black.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-black-italic';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-bold';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-bold-italic';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-extra-light';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-extra-light-italic';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-italic';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-Italic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-light';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-light-italic';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-LightItalic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-regular';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-semi-bold';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'sans-pro-semi-bold-italic';
    src: url('https://cdn.remotederm.ca/fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
    font-display: swap;
}