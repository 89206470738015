.home-welcome-image {
  width: 100%;
  height: 564px;
}

.home-skin-image {
  width: 100%;
  height: 347px;
}


.how-it-works {
  width: 296px;
  height: 640px;
}

.how-it-works-section {
  height: 640px;
}

.home-specialists-container {
  width: 100%;
  height: 699px;
}


.home-specialists-container-img {
  height: 478px;
}



.home-country-container {
  height: auto;
}


.home-country-container .image-container img {
  width: 443px;
}


.home-country-container .image-container {
  background: transparent radial-gradient(closest-side at 50% 50%, #381A29 0%, #381A2900 100%) 0% 0% no-repeat padding-box;
}


.comment-slider .slick-slide.slick-active.slick-center.slick-current {
  margin-top: -20px;
}

.terms-section {
  height: calc(100% - 100px);
}

.MuiSvgIcon-root {
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}


@media (min-width: 1024px) {
  .home-welcome-image {
    width: 100%;
    height: 859px;
  }

  .home-skin-image {
    width: 100%;
    height: 629px;
  }
  .how-it-works {
    width: 296px;
    height: 640px;
  }

  .home-country-container {
    height: 588px;
  }

}