//font class
.font-sans-pro-black{ font-family: 'sans-pro-black'; }
.font-sans-pro-black-italic{ font-family: 'sans-pro-black-italic'; }
.font-sans-pro-bold{ font-family: 'sans-pro-bold'; }
.font-sans-pro-bold-italic{ font-family: 'sans-pro-bold-italic'; }
.font-sans-pro-extra-light{ font-family: 'sans-pro-extra-light'; }
.font-sans-pro-extra-light-italic{ font-family: 'sans-pro-extra-light-italic'; }
.font-sans-pro-italic{ font-family: 'sans-pro-italic'; }
.font-sans-pro-light{ font-family: 'sans-pro-light'; }
.font-sans-pro-light-italic{ font-family: 'sans-pro-light-italic'; }
.font-sans-pro-regular{ font-family: 'sans-pro-regular'; }
.font-sans-pro-semi-bold{ font-family: 'sans-pro-semi-bold'; }
.font-sans-pro-semi-bold-italic{ font-family: 'sans-pro-semi-bold-italic'; }
.title-case { text-transform: capitalize; }


//font sizes
.font-size-10{ font-size: 10px; }
.font-size-12{ font-size: 12px; }
.font-size-14{ font-size: 14px; }
.font-size-16{ font-size: 16px; }
.font-size-18{ font-size: 18px; }
.font-size-24{ font-size: 24px; }
.font-size-28{ font-size: 28px; }
.font-size-42{ font-size: 42px; }

//z index
.index-z-1{ z-index: 1; }
.index-z-2{ z-index: 2; }
.index-z-3{ z-index: 3; }
.index-z-4{ z-index: 4; }
.index-z-5{ z-index: 5; }
.index-z-6{ z-index: 6; }
.index-z-7{ z-index: 7; }
.index-z-8{ z-index: 8; }
.index-z-9{ z-index: 9; }


//line height
.line-spacing-15{ line-height: 15px; }
.line-spacing-18{ line-height: 18px; }
.line-spacing-21{ line-height: 21px; }
.line-spacing-24{ line-height: 24px; }
.line-spacing-27{ line-height: 27px; }
.line-spacing-36{ line-height: 36px; }
.line-spacing-40{ line-height: 40px; }
.line-spacing-54{ line-height: 54px; }


//border color
.border-color-FFFFFF80{
    border-color: #FFFFFF80;
}

.border-color-150910{
    border-color: #150910;
}

.border-color-FFFFFFCC{
    border-color: #FFFFFFCC;
}

.border-color-c46182{
    border-color: #c46182;
}



//font colors
.color-f8f3e2{ color : #F8F3E2;}
.color-857542{ color : #857542;}
.color-e5cf8b{ color : #E5CF8B;}
.color-2c1623{ color : #2C1623;}
.color-2D2926{ color : #2D2926;}
.color-dfc46e-warning{ color : #DFC46E }
.color-ffffff{ color : #FFFFFF;}
.color-3c5981{ color : #3C5981;}
.color-e0e9f7{ color : #E0E9F7;}
.color-de8791{ color : #DE8791;}
.color-c56183{ color : #C56183;}
.color-c05e6a{ color : #C05E6A;}
.color-C23A61{ color : #C23A61;}
.color-803f46{ color : #803F46;}
.color-b15775{ color : #B15775;}
.color-d0809b{ color : #D0809B;}
.color-6495d8{ color : #6495D8;}
.color-5a86c2{ color : #5A86C2;}
.color-f6e1e3{ color : #F6E1E3;}
.color-d66976-error{ color : #D66976;}
.color-92b4e3{ color : #92B4E3;}
.color-c56183-eye-catching{ color : #C56183;}
.color-f3dfe6{ color : #F3DFE6;}
.color-a0dcc7{ color : #A0DCC7;}
.color-3a7661{ color : #3A7661;}
.color-dff3ec{ color : #DFF3EC;}
.color-150910-secondary-1{ color :#150910} ;
.color-636363-secondary-2{ color :#636363} ;
.color-a1a1a1{ color : #A1A1A1;}
.color-828282{ color : #828282;}
.color-c0c0c0{ color : #C0C0C0;}
.color-efefef{ color : #EFEFEF;}
.color-f8f8f8{ color : #F8F8F8;}
.color-dfdfdf{ color : #DFDFDF;}
.color-61c5a3-sucess{ color : #61C5A3;}
.color-c8b063{ color : #C8B063;}
.color-57b192{ color : #57B192;}
.color-FFFFFFCC{ color : #FFFFFFCC;}
.color-FFFFFF80{ color : #FFFFFF80;}


//background colors
.bg-color-f8f3e2{ background-color: #F8F3E2;}
.bg-color-857542{ background-color: #857542;}
.bg-color-e5cf8b{ background-color: #E5CF8B;}
.bg-color-F1E172{ background-color: #F1E172;}
.bg-color-FF9B55{ background-color: #FF9B55;}
.bg-color-FFC72C{ background-color: #FFC72C;}
.bg-color-F9DD3D{ background-color: #f9dd3d;}
.bg-color-B886FF{ background-color: #B886FF;}
.bg-color-2c1623{ background-color: #2C1623;}
.bg-color-dfc46e-warning{ background-color: #DFC46E }
.bg-color-ffffff{ background-color: #FFFFFF;}
.bg-color-3c5981{ background-color: #3C5981;}
.bg-color-e0e9f7{ background-color: #E0E9F7;}
.bg-color-de8791{ background-color: #DE8791;}
.bg-color-c05e6a{ background-color: #C05E6A;}
.bg-color-803f46{ background-color: #803F46;}
.bg-color-b15775{ background-color: #B15775;}
.bg-color-d0809b{ background-color: #D0809B;}
.bg-color-6495d8{ background-color: #6495D8;}
.bg-color-5a86c2{ background-color: #5A86C2;}
.bg-color-f6e1e3{ background-color: #F6E1E3;}
.bg-color-d66976-error{ background-color: #D66976;}
.bg-color-92b4e3{ background-color: #92B4E3;}
.bg-color-b4cef3{ background-color: #cedbf0;}
.bg-color-c56183-eye-catching{ background-color: #C56183;}
.bg-color-7bc3a5{ background-color: #7BC3A5;}
.bg-color-f3dfe6{ background-color: #F3DFE6;}
.bg-color-a0dcc7{ background-color: #A0DCC7;}
.bg-color-3a7661{ background-color: #3A7661;}
.bg-color-dff3ec{ background-color: #DFF3EC;}
.bg-color-150910-secondary-1{ background-color:#150910} ;
.bg-color-636363-secondary-2{ background-color:#636363} ;
.bg-color-a1a1a1{ background-color: #A1A1A1;}
.bg-color-828282{ background-color: #828282;}
.bg-color-c0c0c0{ background-color: #C0C0C0;}
.bg-color-efefef{ background-color: #EFEFEF;}
.bg-color-f8f8f8{ background-color: #F8F8F8;}
.bg-color-dfdfdf{ background-color: #DFDFDF;}
.bg-color-61c5a3-sucess{ background-color: #61C5A3;}
.bg-color-c8b063{ background-color: #C8B063;}
.bg-color-15091080{ background-color: #15091080;}
.bg-color-57b192{ background-color: #57B192;}
.bg-color-FFFFFFCC{ background-color: #FFFFFFCC;}
.bg-color-A0DCC780{ background-color: #A0DCC780;}
.bg-color-FFFFFF33{ background-color: #FFFFFF33;}
.bg-color-3b3da233{ background-color: #3b3da233;}

