.rules-content{
  max-height: none;
}

.title-button-rules:hover{
  color : #C56183;
}



@media (min-width: 768px) { 
  .rules-content{
    max-height: 600px;
  }
}